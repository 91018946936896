import React, { forwardRef } from "react";

const ProfileModal = forwardRef(({ name, mobileNumber }, ref) => {
  return (
    <div
      className="modal fade"
      id="modalProfile"
      tabIndex="-1"
      aria-hidden="true"
      ref={ref}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCenterTitle">
              Profile
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col mb-3">
                <label htmlFor="nameWithTitle" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="nameWithTitle"
                  className="form-control"
                  value={name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row g-2">
              <div className="col mb-0">
                <label htmlFor="emailWithTitle" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="emailWithTitle"
                  className="form-control"
                  value={mobileNumber}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {/* <button type="button" className="btn btn-primary">
              Save
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProfileModal;
