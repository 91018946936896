import React, { useEffect } from 'react'
import "../otherFiles/css/ChatPage.css";
import Footer from "../components/ChatPage/Footer.jsx";
import Navbar from "../components/ChatPage/Navbar.jsx";
import Whatsapp from '../helper/Whatsapp.jsx';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3
                  className="text-start"
                  style={{ fontWeight: "bold", marginTop: "20px" }}
                >
                  Privacy Policy
                </h3>
                <hr />
                <p className="text-end">
                  <i>
                    Last Updated On: 10<sup>th</sup> September, 2024
                  </i>
                </p>
                <div
                  className="column"
                  style={{ marginTop: "25px", color: "black" }}
                >
                  <div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>1. Introduction</h4>
                      <p>
                        This Privacy Policy outlines how we collect, use, and
                        protect your personal data while ensuring fairness,
                        reliability, and security in AI-driven services. By
                        using our services, you agree to the terms outlined
                        here.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        2. Information We Collect
                      </h4>
                      <p>
                        We collect personal data you provide, such as your name,
                        email, and payment details. Additionally, our AI systems
                        may analyze behavioral data to improve service quality
                        and personalize experiences. Our systems comply with
                        privacy laws to safeguard your data and ensure
                        transparency.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        3. How We Use Your Information
                      </h4>
                      <p>
                        Your data is used to provide, improve, and personalize
                        services. We implement Responsible AI principles to
                        ensure that data-driven decisions are fair and reliable,
                        treating all users equally. Our AI systems adhere to
                        rigorous standards, ensuring safe operations under
                        varied conditions.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        4. Privacy and Security
                      </h4>
                      <p>
                        We prioritize your privacy by adhering to strict
                        security protocols, encrypting your data in transit and
                        at rest. Our systems incorporate privacy-enhancing
                        technologies like differential privacy to ensure that
                        personal data remains protected. Access to data is
                        restricted to authorized personnel only.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>5. Responsible AI</h4>
                      <p>
                        In compliance with Responsible AI framework, we
                        implement fairness, inclusiveness, transparency,
                        accountability, and privacy principles. Our AI models
                        are regularly assessed for bias and are designed to
                        treat all users equitably.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>6. User Control</h4>
                      <p>
                        You have control over how your data is used. You can
                        access, modify, or delete your personal information by
                        contacting us. We also ensure transparency by providing
                        explanations for AI decisions when they impact
                        significant aspects of your life.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        7. Third-Party Sharing
                      </h4>
                      <p>
                        We do not share your data with third parties without
                        your explicit consent, except where required by law. Any
                        sharing follows privacy laws, ensuring data is processed
                        responsibly.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>8. Accountability</h4>
                      <p>
                        Our organization holds itself accountable for AI
                        decisions and the safeguarding of personal data. We
                        monitor AI system performance, and our Responsible AI
                        Scorecard ensures continuous oversight.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        9. Security Measures
                      </h4>
                      <p>
                        We employ best practices in data security, including
                        vulnerability scans, policy auditing, and secure machine
                        learning operations (MLOps). We also use various tools
                        to simulate and prevent cyberattacks on AI systems.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        10. Changes to This Policy
                      </h4>
                      <p>
                        We may update this Privacy Policy periodically. Any
                        changes will be communicated promptly through our
                        services.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        11. Contact Information
                      </h4>
                      <p>
                        If you have any questions about this Privacy Policy,
                        please contact us at{" "}
                        <a href="https://wa.me/message/VH5HQOAHFJFBK1">
                          WhatsApp
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
            <Whatsapp />
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default Privacy
