import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import "../otherFiles/css/Signup.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/ChatPage/Footer";
import { getOtp, verifyOtp } from "../utils/auth.js";
import { toast } from "sonner";
import Loader from "../helper/Loader.jsx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../otherFiles/css/phoneInput.css";
import Whatsapp from "../helper/Whatsapp.jsx";

const Signup = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [accept, setAccept] = useState(false);

  useEffect(() => {
    const verified =
      JSON.parse(localStorage.getItem("isVerified")) ||
      JSON.parse(sessionStorage.getItem("isVerified"));
    if (verified) {
      navigate("/");
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async () => {
    if (!name || !password || !mobileNumber) {
      toast.error("Please Fill In All Fields");
      return;
    }

    if (!accept) {
      toast.error("Please Accept The Terms & Conditions");
      return;
    }

    setLoader(true);
    try {
      const otpResponse = await getOtp(mobileNumber);

      if (otpResponse?.status === true) {
        setOtp(otpResponse); // Store the received OTP
        toast.success("OTP Sent Successfully");
      } else {
        toast.error("Failed To Send OTP");
      }
    } catch (error) {
      console.error("Error fetching OTP:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!enteredOtp) {
      toast.error("Please Enter OTP");
      return;
    }

    setLoader(true);
    try {
      const verifyResponse = await verifyOtp(name, mobileNumber, password, enteredOtp);

      if (verifyResponse?.status === true) {
        navigate("/signin");
        toast.success("OTP Verified! Signed Up Successfully");
      } else {
        navigate("/signup");
        toast.error("Failed To Verify OTP");
      }
    } catch (error) {
      navigate("/signup");
      console.error("Error verifying OTP:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const handlePhone = (e) => {
    while (e?.charAt(0) === "+") {
      e = e.substring(1);
    }
    setMobileNumber(e);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner">
              <div className="card">
                <div className="card-body">
                  <div className="app-brand justify-content-center">
                    <div className="app-brand-link gap-2">
                      <span>
                        <img
                          src={Logo}
                          style={{ width: "50px", height: "auto" }}
                          alt="Logo"
                        />
                      </span>
                      <span className="app-brand-text demo text-body fw-bolder">
                        Think Chat
                      </span>
                    </div>
                  </div>
                  <h4 className="mb-2 text-capitalize">
                    Welcome To Think Chat! 🚀
                  </h4>
                  <p className="mb-4">
                    Create an account to start your seamless chat journey with
                    Think Chat!
                  </p>
                  <div id="formAuthentication" className="mb-3">
                    <div
                      hidden={otp?.status === true ? true : false}
                      className="mb-3"
                    >
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        readOnly={otp?.status === true ? true : false}
                        disabled={otp?.status === true ? true : false}
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div
                      hidden={otp?.status === true ? true : false}
                      className="mb-3"
                    >
                      <label htmlFor="mobile" className="form-label">
                        Mobile Number ( WhatsApp )
                      </label>
                      <PhoneInput
                        defaultCountry="IN"
                        id="mobile"
                        onChange={(e) => handlePhone(e)}
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </div>
                    <div
                      hidden={otp?.status === true ? true : false}
                      className="mb-3 form-password-toggle"
                    >
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          readOnly={otp?.status === true ? true : false}
                          disabled={otp?.status === true ? true : false}
                          type={passwordVisible ? "text" : "password"}
                          className="form-control"
                          id="password"
                          name="password"
                          aria-describedby="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          <i
                            className={`bx ${
                              passwordVisible ? "bx-show" : "bx-hide"
                            }`}
                          ></i>
                        </span>
                      </div>
                    </div>
                    {otp?.status === true && (
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="otp" className="form-label">
                            Enter OTP
                          </label>
                          <Link onClick={handleSubmit}>
                            <small>Resend OTP</small>
                          </Link>
                        </div>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            id="otp"
                            name="otp"
                            onChange={(e) => setEnteredOtp(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      hidden={otp?.status === true ? true : false}
                      className="mb-3"
                    >
                      <div className="form-check">
                        <input
                          readOnly={otp?.status === true ? true : false}
                          disabled={otp?.status === true ? true : false}
                          className="form-check-input"
                          type="checkbox"
                          id="terms-conditions"
                          name="terms"
                          value={accept}
                          onChange={(e) => setAccept(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="terms-conditions"
                        >
                          I Accept The{" "}
                          <Link to="/terms-and-conditions">
                            Terms &amp; Conditions
                          </Link>
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary d-grid w-100"
                      onClick={
                        otp?.status === true ? handleVerifyOtp : handleSubmit
                      }
                    >
                      {otp?.status === true ? "Verify & Sign Up" : "Sign Up"}
                    </button>
                  </div>
                  <p className="text-center">
                    <span>Already have an account?</span>
                    <Link to="/signin">
                      <span> Sign In Instead</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <Whatsapp />
        </div>
      )}
    </>
  );
};

export default Signup;
