import React from "react";
import "../otherFiles/css/ChatPage.css";
import Footer from "../components/ChatPage/Footer.jsx";
import Navbar from "../components/ChatPage/Navbar.jsx";
import Messages from "../components/ChatPage/Messages.jsx";
import ChatInput from "../components/ChatPage/ChatInput.jsx";
import Whatsapp from "../helper/Whatsapp.jsx";

const ChatPage = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <aside
            id="layout-menu"
            className="layout-menu menu-vertical menu bg-menu-theme"
          >
            <SideNav />
            <div className="menu-inner-shadow"></div>
            <ChatMenu />
          </aside> */}
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <Messages />
                <ChatInput />
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
            <Whatsapp />
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  );
};

export default ChatPage;
