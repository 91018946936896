import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../otherFiles/css/Forgot.css";
import Logo from "../assets/images/logo.png";
import { resetOtp, resetPass } from "../utils/auth";
import { toast } from "sonner";
import Loader from "../helper/Loader";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../otherFiles/css/phoneInput.css";
import Footer from "../components/ChatPage/Footer";
import Whatsapp from "../helper/Whatsapp";

const Forgot = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // Check if user is already logged in
    const verified =
      JSON.parse(localStorage.getItem("isVerified")) ||
      JSON.parse(sessionStorage.getItem("isVerified"));
    if (verified) {
      navigate("/");
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async () => {
    if (!mobileNumber) {
      toast.error("Please Fill In All Fields");
      return;
    }


    setLoader(true);
    try {
      const result = await resetOtp(mobileNumber);

      if (result?.status === true) {
        setOtp(result);
        toast.success("OTP Sent Successfully.");
      } else {
        toast.error("Failed To Send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during forgot password request:", error.message);
      toast.error("An Error Occurred. Please Try Again.");
    } finally {
      setLoader(false);
    }
  };

  const handleResetPassword = async () => {
    if (!password || !confirmPassword || !enteredOtp) {
      toast.error("Please Fill In All Fields");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match");
      return;
    }

    setLoader(true);
    try {
      const response = await resetPass(password, enteredOtp, mobileNumber);

      if (response?.status === true) {
        toast.success("Password Reset Successfully");
        navigate("/signin");
      } else {
        toast.error("Failed To Reset Password");
      }
    } catch (error) {
      toast.error("Error Resetting Password");
    } finally {
      setLoader(false);
    }
  };

  const handlePhone = (e) => {
    while (e?.charAt(0) === "+") {
      e = e.substring(1);
    }
    setMobileNumber(e);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card">
                <div className="card-body">
                  <div className="app-brand justify-content-center">
                    <div className="app-brand-link gap-2">
                      <span>
                        <img
                          src={Logo}
                          style={{ width: "50px", height: "auto" }}
                          alt="Logo"
                        />
                      </span>
                      <span className="app-brand-text demo text-body fw-bolder">
                        Think Chat
                      </span>
                    </div>
                  </div>
                  <h4 className="mb-2 text-capitalize">Forgot Password? 🔒</h4>
                  <p className="mb-4">
                    Enter your mobile number and we'll send you OTP to reset
                    your password
                  </p>
                  <div
                    hidden={otp?.status === true ? true : false}
                    className="mb-3"
                  >
                    <label htmlFor="mobile" className="form-label">
                      Mobile Number ( WhatsApp )
                    </label>
                    <PhoneInput
                      defaultCountry="IN"
                      id="mobile"
                      onChange={handlePhone}
                      inputProps={{
                        name: "mobile",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                  </div>
                  {otp?.status === true && (
                    <>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="otp" className="form-label">
                            Enter OTP
                          </label>
                          <Link onClick={handleSubmit}>
                            <small>Resend OTP</small>
                          </Link>
                        </div>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            id="otp"
                            name="otp"
                            onChange={(e) => setEnteredOtp(e.target.value)} // Set entered OTP in state
                          />
                        </div>
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type={"password"}
                            className="form-control"
                            id="password"
                            name="password"
                            aria-describedby="password"
                            onChange={(e) => setPassword(e.target.value)} // Set password in context
                          />
                        </div>
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <label className="form-label" htmlFor="password">
                          Confirm Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className="form-control"
                            id="password"
                            name="password"
                            aria-describedby="password"
                            onChange={(e) => setConfirmPassword(e.target.value)} // Set password in context
                          />
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            <i
                              className={`bx ${
                                passwordVisible ? "bx-show" : "bx-hide"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    className="btn btn-primary d-grid w-100"
                    onClick={
                      otp?.status === true ? handleResetPassword : handleSubmit
                    }
                  >
                    {otp?.status === true ? "Verify & Reset Password" : "Send OTP"}
                  </button>
                  <div className="text-center mt-3">
                    <Link
                      to="/signin"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                      Back To Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <Whatsapp />
        </div>
      )}
    </>
  );
};

export default Forgot;
