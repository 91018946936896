import React from "react";
import ChatPage from "./ChatPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./Signin";
import Signup from "./Signup";
import Forgot from "./Forgot";
import Privacy from "./Privacy";
import Terms from "./Terms";
import PrivateRoute from "../components/PrivateRoute"; // Import the PrivateRoute component

const Page = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/forgot-password" element={<Forgot />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/terms-and-conditions" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />

        {/* Private Routes */}
        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <ChatPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Page;
