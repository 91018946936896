import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
          <div className="mb-2 mb-md-0">
            © {new Date().getFullYear()} Developed By{" "}
            <a
              className="footer-link fw-bolder"
              target="_blank"
              href="https://www.thinkhat.in/"
              rel="noreferrer"
            >
              Think Hat Business Solutions
            </a>
            <span> | Powered By Meta Llama 3.1</span>
          </div>
          <div>
            <Link
              className="footer-link me-4"
              // target="_blank"
              to="/terms-and-conditions"
              // rel="noreferrer"
            >
              Terms &amp; Conditions
            </Link>
            <Link
              className="footer-link me-4"
              // target="_blank"
              to="/privacy-policy"
              // rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer
