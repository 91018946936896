import React from "react";
import useAuthRedirect from "../hooks/useAuthRedirect.js"; // Ensure this path is correct
import { Link } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useAuthRedirect();

  return isAuthenticated ? children : <Link to="/signin" />;
};

export default PrivateRoute;
