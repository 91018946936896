import React, { useEffect, useState } from "react";
import { postTextPayload } from "../../utils/chat";
import { useUserContext } from "../../context/userContext";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const ChatInput = () => {
  const { setResponseData, setChatData } = useUserContext();
  const [message, setMessage] = useState("");
  const [init, setInit] = useState(true);

  const navigate = useNavigate();

  const sessionData = sessionStorage.getItem("message_history");
  const auth_token =
    sessionStorage.getItem("isVerified") || localStorage.getItem("isVerified");

  useEffect(() => {
    if (!auth_token) {
      toast.error("Unauthenticated! Please Sign In");
      sessionStorage.removeItem("isVerified");
      localStorage.removeItem("isVerified");
      navigate("/signin");
    }
  }, [auth_token, navigate]);

  useEffect(() => {
    if (sessionData) {
      setInit(false);
    } else {
      setInit(true);
    }
  }, [sessionData]);

  const handleSendMessage = async () => {
    if (!message.trim()) {
      return; // Don't send empty messages
    }

    try {
      // Add the user message to chat data
      setChatData((prevChatData) => [
        ...(prevChatData && Array.isArray(prevChatData) ? prevChatData : []),
        { userMessage: message },
      ]);

      setMessage(""); // Clear the input field

      // Send message to get the bot's response
      const data = await postTextPayload(message, init);

      if (data?.status === true) {
        // Add the bot response to the response data
        setResponseData((prevResponseData) => [
          ...(prevResponseData && Array.isArray(prevResponseData)
            ? prevResponseData
            : []),
          { botResponse: data?.current_html_response },
        ]);

        const dataArray = JSON.stringify(data?.message_history);
        if (dataArray) {
          sessionStorage.setItem("message_history", dataArray);
        }
      } else {
        toast.error("Please Sign In Again");
        sessionStorage.clear();
        localStorage.clear();
        setChatData([]);
        setResponseData([]);
        navigate("/signin");
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior (like form submission)
      handleSendMessage();
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 order-0">
          <div className="card">
            <div className="d-flex align-items-end row">
              <div className="col-sm-12">
                <div className="card-body sec-chat-input">
                  <textarea
                    required
                    autoFocus={true}
                    value={message}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control border-0 shadow-none input-chat"
                    placeholder="Message"
                    rows={1} // Keep textarea minimal height
                  />
                  <button
                    disabled={!message.trim()}
                    type="button"
                    onClick={handleSendMessage}
                    className="btn rounded-pill btn-icon btn-primary btn-abs-chat"
                  >
                    <span className="tf-icons bx bx-up-arrow-alt"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
