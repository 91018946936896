import React from 'react'
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo.png";
import useAuthRedirect from "../../hooks/useAuthRedirect";

const SideNav = () => {
  const login = useAuthRedirect();
  return (
    <div>
      <div className="app-brand demo">
        <Link to={login ? "/" : "/signin"} className="app-brand-link">
          <span>
            <img src={Logo} style={{ width: "50px", height: "" }} alt="Logo" />
          </span>
          <span className="app-brand-text demo menu-text fw-bolder ms-2">
            Think Chat
          </span>
        </Link>
        <Link
          to={login ? "/" : "/signin"}
          className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
        >
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </Link>
      </div>
    </div>
  );
}

export default SideNav
