import axios from "axios";
import {
  forgotPassword,
  logout,
  profile,
  resetPassword,
  signin,
  signup,
  verifyNumber,
} from "../API/api.js";

export const getOtp = async (mobileNumber) => {
  const payload = {
    mobile_number: mobileNumber,
  };

  try {
    const response = await axios.post(verifyNumber, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error getting OTP:", error);
    return null;
  }
};

export const verifyOtp = async (name, mobile_number, password, enteredOtp) => {
  const payload = {
    name: name,
    mobile_number: mobile_number,
    password: password,
    OTP: enteredOtp,
  };

  try {
    const response = await axios.post(signup, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error getting OTP:", error);
    return null;
  }
};

export const signinUser = async (password, mobileNumber) => {
  const payload = {
    password: password,
    mobile_number: mobileNumber,
  };

  try {
    const response = await axios.post(signin, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error getting OTP:", error);
    return null;
  }
};

export const resetOtp = async (mobileNumber) => {
  const payload = {
    mobile_number: mobileNumber,
  };

  try {
    const response = await axios.post(forgotPassword, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error getting OTP:", error);
    return null;
  }
};

export const resetPass = async (password, enteredOtp, mobileNumber) => {
  const payload = {
    password: password,
    OTP: enteredOtp,
    mobile_number: mobileNumber,
  };

  try {
    const response = await axios.post(resetPassword, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error(
      "Error during reset password request:",
      error.response || error.message
    );
    return null;
  }
};

export const logoutUser = async () => {
  const auth_token =
    JSON.parse(localStorage.getItem("isVerified")) ||
    JSON.parse(sessionStorage.getItem("isVerified"));
  const payload = {};

  try {
    const response = await axios.post(logout, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token.B}`,
      },
    });
    
    return response?.data;
  } catch (error) {
    console.error("Error getting OTP:", error);
    return null;
  }
};

export const userProfile = async (user_id) => {
  const auth_token =
    JSON.parse(localStorage.getItem("isVerified")) ||
    JSON.parse(sessionStorage.getItem("isVerified"));
  const payload = {
    user_id: user_id,
  };

  try {
    const response = await axios.post(profile, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token.B}`,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error getting Profile:", error);
    return null;
  }
};
