import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserProvider } from "./context/userContext.js";
import { AuthProvider } from "./context/authContext.js";
import { Toaster } from "sonner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <App />
        <Toaster
          theme="light"
          richColors
          position="top-center"
          duration={1500}
        />
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
);
