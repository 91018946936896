import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useAuthRedirect = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuthStatus = () => {
      const verified =
        localStorage.getItem("isVerified") ||
        sessionStorage.getItem("isVerified");
      setIsAuthenticated(verified);

      const publicPaths = ["/terms-and-conditions", "/privacy-policy"];
      const isPublicPath = publicPaths.includes(location.pathname);

      if (!verified && !isPublicPath) {
        navigate("/signin");
      }
    };

    checkAuthStatus();
  }, [navigate, location]);

  return isAuthenticated;
};

export default useAuthRedirect;
