import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProfileModal from "./ProfileModal";
import { Modal } from "bootstrap";
import SideNav from "./SideNav";
import useAuthRedirect from "../../hooks/useAuthRedirect";
import user from "../../assets/images/user.png";
import { logoutUser, userProfile } from "../../utils/auth";
import { toast } from "sonner";
import Loader from "../../helper/Loader";
import { useUserContext } from "../../context/userContext";

const Navbar = () => {
  const { setChatData, setResponseData } = useUserContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isAuthenticated = useAuthRedirect();
  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const navigate = useNavigate();

  const auth_token =
    sessionStorage.getItem("isVerified") || localStorage.getItem("isVerified");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleModalOpen = () => {
    if (!auth_token) {
      toast.error("Unauthenticated! Please Sign In");
      sessionStorage.removeItem("isVerified");
      localStorage.removeItem("isVerified");
      navigate("/signin");
    }
    if (auth_token && modalRef.current) {
      const modal = new Modal(modalRef.current);
      modal.show();
      fetchUserData();
    }
  };

  const fetchUserData = async () => {
    try {
      const user_id =
        JSON.parse(localStorage.getItem("isVerified")) ||
        JSON.parse(sessionStorage.getItem("isVerified"));
      const result = await userProfile(user_id.A);
      if (result?.status === true) {
        setName(result?.data?.name);
        setMobileNumber("+" + result?.data?.mobile_number);
      } else {
        toast.error("Please Sign In Again");
        sessionStorage.clear();
        localStorage.clear();
        setChatData([]);
        setResponseData([]);
        navigate("/signin");
        return;
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleLogout = async () => {
    setLoader(true);
    try {
      // eslint-disable-next-line
      const result = await logoutUser();
      sessionStorage.clear();
      localStorage.clear();
      setChatData([]);
      setResponseData([]);
      navigate("/signin");
      toast.success("Logged Out Successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <nav
            className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar"
          >
            {/* <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <Link className="nav-item nav-link px-0 me-xl-4" to="/">
            <i className="bx bx-menu bx-sm"></i>
          </Link>
        </div> */}
            <div
              className="navbar-nav-right d-flex align-items-center"
              id="navbar-collapse"
            >
              <SideNav />
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                {isAuthenticated ? (
                  <li
                    className={`nav-item navbar-dropdown dropdown-user dropdown ${
                      dropdownOpen ? "show" : ""
                    }`}
                    ref={dropdownRef}
                  >
                    <Link
                      className="nav-link dropdown-toggle hide-arrow"
                      to="#"
                      onClick={toggleDropdown}
                    >
                      <div className="avatar avatar-online">
                        <div className="w-px-40 h-px-40 d-flex justify-content-center align-items-center rounded-circle bg-primary text-white fw-bold">
                          <img src={user} alt="User Icon" />
                        </div>
                      </div>
                    </Link>
                    <ul
                      className={`dropdown-menu dropdown-menu-end ${
                        dropdownOpen ? "show" : ""
                      }`}
                      style={{ minWidth: "150px", top: "50px", right: "0px" }}
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={handleModalOpen}
                        >
                          <i className="bx bx-user me-2"></i>
                          <span className="align-middle">My Profile</span>
                        </Link>
                      </li>
                      <li>
                        <div className="dropdown-divider"></div>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          <i className="bx bx-power-off me-2"></i>
                          <span className="align-middle">Log Out</span>
                        </button>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <div style={{ display: "flex", gap: "6px" }}>
                    <Link to="/signin">
                      <button
                        className="btn btn-primary d-grid w-100"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </Link>
                    <Link to="/signup">
                      <button className="btn btn-primary d-grid w-100">
                        Sign up
                      </button>
                    </Link>
                  </div>
                )}
              </ul>
            </div>
          </nav>
          <ProfileModal
            ref={modalRef}
            name={name}
            mobileNumber={mobileNumber}
          />
        </div>
      )}
    </>
  );
};

export default Navbar;
