// const host = "http://localhost:5000";
const host = "https://www.thinkchat.theproapp.com";

export const verifyNumber = `${host}/verify_number`;
export const signup = `${host}/register`;
export const signin = `${host}/login`;
export const forgotPassword = `${host}/forgot_password`;
export const resetPassword = `${host}/reset_password`;
export const logout = `${host}/logout`;
export const profile = `${host}/profile`;

export const sendChat = `${host}/chat`;
