import axios from "axios";
import { sendChat } from "../API/api";

export const postTextPayload = async (textData, init) => {
  const auth_token =
    JSON.parse(localStorage.getItem("isVerified")) ||
    JSON.parse(sessionStorage.getItem("isVerified"));
  const payload = {
    text: textData,
    init: init,
  };
  try {
    const response = await axios.post(sendChat, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token.B}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error posting data:", error);
  }
};
