import React, { useEffect } from "react";
import "../otherFiles/css/ChatPage.css";
import Footer from "../components/ChatPage/Footer.jsx";
import Navbar from "../components/ChatPage/Navbar.jsx";
import Whatsapp from "../helper/Whatsapp.jsx";
import { Link } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h3
                  className="text-start"
                  style={{ fontWeight: "bold", marginTop: "20px" }}
                >
                  Terms & Conditions
                </h3>
                <hr />
                <p className="text-end">
                  <i>
                    Last Updated On: 10<sup>th</sup> September, 2024
                  </i>
                </p>
                <div
                  className="column"
                  style={{ marginTop: "25px", color: "black" }}
                >
                  <div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>1. Introduction</h4>
                      <p>
                        Welcome to Think Chat, an AI-powered virtual assistant
                        developed by Think Hat Business Solutions using Llama
                        3.1. By accessing or using Think Chat, you agree to
                        comply with and be bound by the following Terms &
                        Conditions. Please read them carefully.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        2. Legal Information
                      </h4>
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Company Name:{" "}
                          </span>
                          <span>Think Hat Business Solutions</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Address: </span>
                          <span>
                            Eternia, Badu Road, Madhyamgram, Kolkata, West
                            Bengal 700155
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        3. Acceptance of Terms
                      </h4>
                      <p>
                        By accessing or using Think Chat, you acknowledge that
                        you have read, understood, and agree to be bound by
                        these Terms & Conditions, as well as any applicable laws
                        and regulations. If you do not agree to these terms, you
                        are prohibited from using Think Chat.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        4. Service Description
                      </h4>
                      <p>
                        Think Chat is a generative AI application designed to
                        communicate in multiple languages based on user prompts.
                        It provides responses in the same language the user
                        inputs and aims to offer helpful information based on
                        queries. However, Think Chat is in the improvement stage
                        and should be used with the understanding that its
                        responses may not be fully accurate or comprehensive.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        5. User Responsibilities
                      </h4>
                      <p>
                        <b>Accuracy of Prompts:</b> Users are responsible for
                        ensuring that the prompts they provide to Think Chat are
                        clear, legal, and free from harmful content.
                      </p>
                      <p>
                        <b>Prohibited Uses:</b> Users agree not to use Think
                        Chat for any illegal activities, including but not
                        limited to:
                      </p>
                      <div className="m-3">
                        <p>i) Distributing false or misleading information.</p>
                        <p>ii) Violating intellectual property rights.</p>
                        <p>
                          iii) Using the platform for abusive, offensive, or
                          harmful content.
                        </p>
                        <p>
                          iv) Sharing personal, confidential, or sensitive data
                          that could pose a risk if disclosed.
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        6. Limitation of Liability
                      </h4>
                      <p>
                        Think Chat is provided on an "as is" and "as available"
                        basis. Think Hat Business Solutions makes no warranties,
                        either expressed or implied, concerning the accuracy,
                        reliability, or availability of the service. We are not
                        liable for any damages resulting from the use or
                        inability to use Think Chat, including but not limited
                        to:
                      </p>
                      <div className="m-3">
                        <p>i) Errors or omissions in content.</p>
                        <p>ii) Delays or interruptions in service.</p>
                        <p>iii) Any loss of data or profits.</p>
                      </div>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>7. Data Privacy</h4>
                      <p>
                        Think Hat Business Solutions respects your privacy.
                        However, Think Chat collects and processes data based on
                        your interactions to improve its service. We do not sell
                        or share personal data with third parties, except as
                        required by law. By using Think Chat, you agree to our
                        collection and use of data as described in our
                        <Link to="/privacy-policy"> Privacy Policy</Link>.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        8. Intellectual Property
                      </h4>
                      <p>
                        All content, materials, algorithms, and technologies
                        used in Think Chat are the property of Think Hat
                        Business Solutions or its licensors. Users are granted a
                        limited, non-exclusive, and non-transferable license to
                        use Think Chat solely for personal, non-commercial use.
                        Users may not modify, reproduce, or distribute Think
                        Chat's code, content, or services without prior written
                        permission from Think Hat Business Solutions.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        9. Third-Party Services
                      </h4>
                      <p>
                        Think Chat may integrate or interact with third-party
                        services. These third-party services have their own
                        terms and conditions, and users are encouraged to review
                        them. Think Hat Business Solutions is not responsible
                        for any third-party services used in connection with
                        Think Chat.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>
                        10. Modification of Terms
                      </h4>
                      <p>
                        Think Hat Business Solutions reserves the right to
                        modify these Terms & Conditions at any time. Any changes
                        will be posted on this page, and continued use of Think
                        Chat after such changes have been posted constitutes
                        your acceptance of the modified terms.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>11. Termination</h4>
                      <p>
                        Think Hat Business Solutions may suspend or terminate
                        your access to Think Chat at any time for violation of
                        these Terms & Conditions or any applicable laws. You may
                        also discontinue your use of Think Chat at any time.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>12. Governing Law</h4>
                      <p>
                        These Terms & Conditions are governed by and construed
                        in accordance with the laws of India, without regard to
                        conflict of law principles. Any disputes arising from
                        these terms shall be resolved in the courts of Indian
                        Jurisdiction.
                      </p>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <h4 style={{ fontWeight: "bold" }}>13. Contact Information</h4>
                      <p>
                        If you have any questions about these Terms & Conditions,
                        please contact us at{" "}
                        <a href="https://wa.me/message/VH5HQOAHFJFBK1">
                          WhatsApp
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
            <Whatsapp />
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
};

export default Terms;
