import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import copy from "../assets/images/copy.png";
import tick from "../assets/images/tick.png";

const CodeHighlighter = ({ response }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          marginTop: "10px",
          marginBottom: "-10px",
          backgroundColor: "#181C14",
          color: "white",
          borderStartStartRadius: "8px",
          borderStartEndRadius: "8px",
        }}
      >
        <div>Example Code</div>
        {copied ? (
          <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#E2F8FB",
              display: "flex",
              gap: "5px",
              paddingLeft: "3px",
              paddingRight: "3px",
              paddingTop: "2px",
              paddingBottom: "2px",
              alignItems: "center",
            }}
          >
            <img src={tick} alt="Copy" style={{ width: "16px" }} />
            <p style={{ fontSize: "12px" }}>Copied!</p>
          </button>
        ) : (
          <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#E2F8FB",
              display: "flex",
              gap: "5px",
              paddingLeft: "7px",
              paddingRight: "7px",
              paddingTop: "2px",
              paddingBottom: "2px",
              alignItems: "center",
            }}
            onClick={() => {
              navigator.clipboard.writeText(response);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          >
            <img src={copy} alt="Copy" style={{ width: "18px" }} />
            <p style={{ fontSize: "12px" }}>Copy Code</p>
          </button>
        )}
      </div>
      <div>
        <SyntaxHighlighter
          language="jsx"
          style={materialDark}
          customStyle={{
            borderEndStartRadius: "8px",
            borderEndEndRadius: "8px",
            borderStartEndRadius: "0px",
            borderStartStartRadius: "0px",
          }}
        >
          {response}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeHighlighter;
