import React, { createContext, useContext, useState } from "react";

// Creating the AuthContext
const AuthContext = createContext();

// AuthProvider component that will wrap the app and provide context values
export const AuthProvider = ({ children }) => {
  const [user_id, setUser_id] = useState("");

  return (
    <AuthContext.Provider
      value={{
        user_id,
        setUser_id,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuthContext = () => useContext(AuthContext);
